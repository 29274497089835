<script>
import filtersMixin from "@/mixins/filtersMixin";
import User from "@/model/user";
import { mapGetters } from "vuex";
import {
  userTypes,
  FTPermissionsToActions
} from "@/middleware/pageAccessManager.js";
import pageAccessComponent from "./pageAccessComponent";
import featureToggleMixin from "../../../mixins/featureToggleMixin";
export default {
  name: "UserDetails",
  mixins: [filtersMixin, featureToggleMixin],
  components: { pageAccessComponent },
  props: {
    type: {
      default: () => {
        "details";
      }
    },
    loading: {
      default: true
    }
  },
  data: function () {
    return {
      types: {
        details: "details",
        userAccessMgmtUpdatePassword: "userAccessMgmtUpdatePassword",
        userAccessMgmtEdit: "userAccessMgmtEdit",
        userAccessMgmtCreate: "userAccessMgmtCreate"
      },
      userFields: {
        first_name: "Usrs_first_name",
        last_name: "Usrs_last_name",
        email: "Comn_email_address",
        mobile: "Comn_mobile_num",
        country: "Comn_country",
        timezone: "Comn_timezone"
      },
      checkedPermissionsNew: FTPermissionsToActions,
      isIndeterminateLocations: true,
      checkAllLocations: false,
      checkedLocations: []
    };
  },
  computed: {
    ...mapGetters("userAccessManagement", {
      getSidePanelState: "getSidePanelState"
    }),
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    checkedPermissions() {
      return this.getUserData.parsedPermissions;
    },
    checkedUserLocations() {
      return this.getUserData.allowed_locations;
    },
    locations() {
      return this.$store.getters["user/getUserLocations"];
    },
    locationIds() {
      return this.locations.map((location) => location._id);
    },
    getNewUser() {
      return new User();
    },
    enableLocations: function () {
      return (
        ["POND_WORKER", "POND_TECHNICIAN", "POND_MANAGER", ""].indexOf(
          this.getUserData.user_type
        ) > -1
      );
    }
  },
  render(h) {
    return (
      <div class="user-information-section right-panel">
        <layout-toolbar
          justify="start"
          class="user-information-section__header"
        >
          <span class="material-icons-outlined">info</span>
          <p class="user-information-section__header-title">
            {this.ftm__capitalize(this.$t("Usrs_necessary_info_update"))}
          </p>
        </layout-toolbar>

        <el-row class="user-information-section__content right-panel-content-alignment">
  {Object.keys(this.userFields).map((key) => {
    const isVerifiedField = key === "email" || key === "mobile";

    // Handle mobile and phone dynamically
    const fieldValue = key === "mobile"
      ? (this.ftgm__isUserHasFeature("allowed_mobile_login")) && (this.getUserData.mobile || this.getUserData.phone) // Prioritize mobile, fallback to phone
      : this.getUserData[key];

    // Determine if the field is verified (true/false)
    const isVerified =
      key === "email"
        ? this.getUserData.email_verified
        : key === "mobile"
        ? this.getUserData.mobile_verified
        : false;

    return (
      <div class="user-information-section__content-row">
        {/* Left Section - Icons + Labels */}
        <div class="user-information-section__label">
            <span
              class={`material-icons-outlined user-profile-icon ${
                fieldValue && isVerifiedField
                  ? isVerified
                    ? "user-profile-verified"
                    : "user-profile-not-verified"
                  : "placeholder-icon"
              }`}

              title={fieldValue && isVerifiedField ? (isVerified ? this.$t('comn_verified') : this.$t('comn_not_verified')) : ""}
            >
              {fieldValue && isVerifiedField ? (isVerified ? "verified" : "new_releases") : "check_circle"}

            </span>

          <p class="user-information-section__content-column">{this.$t(this.userFields[key])}</p>
        </div>

        {/* Right Section - Values */}
        <div class="user-information-section__value">
          {this.getUserFieldView(h, key, this.getUserData)}
        </div>
      </div>
    );
  })}
</el-row>

        {this.getPageAccessManagementComponent(h)}
      </div>
    );
  },
  methods: {
    getPageAccessManagementComponent(h) {
      const layout = this.getUserTypeToPageAccessLayout(
        this.getUserData.user_type
      );
      if (layout.length && this.getUserData.user_type !== 'ACCOUNT_MANAGER_SUB_USER' && this.getUserData.user_type !== 'ACCOUNT_MANAGER') {
        return (
          <pageAccessComponent
            layout={layout}
            locations-list={this.locations}
            permissions-list={this.$gblUAMPermissionsToPermissionDetailsList}
            user-details={this.getUserData}
            editable={false}
          ></pageAccessComponent>
        );
      }
    },
    getUserTypeToPageAccessLayout(userType) {
      switch (userType) {
        case userTypes.pond_owner:
        case userTypes.admin:
        case userTypes.no_access:
          return [];
        case userTypes.pond_manager:
          return ["locations"];
        default:
          return ["permissions", "locations"];
      }
    },
    getUserFieldView(h, field, fieldModel) {
      switch (field) {
        case "country":
        case "timezone":
          return (
            <p class="user-information-section__content-column" style="color: #1f2041">
              {this.ftm__capitalize(fieldModel[field]?.name)}
            </p>
          );

        case "mobile": {
          const mobileNumber = this.ftm__objectValueByPath(fieldModel, "mobile", null) ||
                              this.ftm__objectValueByPath(fieldModel, "phone", null);

          return (
            <p class="user-information-section__content-column">
              {this.ftm__formatEmpty(mobileNumber)}
            </p>
          );
        }

        default:
          return (
            <p class="user-information-section__content-column">
              {this.ftm__formatEmpty(this.ftm__objectValueByPath(fieldModel, field))}
            </p>
          );
      }
    }
  }
};
</script>

<style lang="scss">

.right-panel {
  padding-bottom: 20px;
  .user-information-section {
    &__header {
      font-weight: 700;
      @include normal-text;
      color: $primary-color;
      &:first-child {
        padding: 25px 15px 0px;
      }
    }
    &__content {
      $parent-scope: &;
      &-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        flex-wrap: wrap;
      }
      &-column {
        color: #1f2041;
        @include small-text;
        &:first-child {
          width: 200px;
          color: #6c7b8a;
        }
      }
    }
    &__label {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 180px;
    }
    &__value {
      flex-grow: 1;
      text-align: left;
    }
  }

  .user-profile-icon {
    width: 24px;
    text-align: center;
  }

  .placeholder-icon {
    visibility: hidden;
  }

  .user-profile-verified {
    color: #57e657;
    font-size: 14px;
  }

  .user-profile-not-verified {
    color: #ed6c41;
    font-size: 14px;
  }
}
</style>
