<template>
  <el-scrollbar
    class="user-table-scroll user-list-table"
    viewClass="user-table-scroll-view"
    wrapClass="user-table-scroll-wrap"
  >
    <er-data-tables
      v-show="!loading"
      :tableData="getOtherUserTableData"
      :columns="tableColumns"
      :el-table-props="tableProps"
      :action-column="false"
      :sortMethodForColumns="sortMethodForColumns"
      :key="$i18n.locale + '1'"
      type="white-header-table"
      layout="table"
      unique-id="user-access-managment__table"
      @row-click="handleRowClick"
      :searchOnTableData="userSearch"
    >
      <template v-slot:first_name="{ row }">
        <div>
          <p class="table-header-text captalize">
            {{ `${row.data.first_name} ${row.data.last_name}` }}
          </p>
          <p class="table-main-text">{{ ftm__formatEmpty(row.data.email || row.data.mobile || row.data.phone) }}</p>
        </div>
      </template>
      <template v-slot:status="{ row }">
        <div
          :class="[
            'user-status-tag',
            getStatus(row.data.user_type).status === 'ACTIVE' && 'is-active'
          ]"
        >
          <p>{{ getStatus(row.data.user_type).lang }}</p>
        </div>
      </template>
      <template v-slot:user_type="{ row }">
        <p class="table-main-text">
          {{ ftm__capitalize($tc(getUserTypeLangStrs[row.data.user_type], 1)) }}
        </p>
      </template>
      <template v-if="getSkrettingGroupTableData.length > 0" v-slot:append>{{
        $t("skretting-group")
      }}</template>
    </er-data-tables>
    <er-data-tables
      v-show="!loading"
      v-if="getSkrettingGroupTableData.length > 0"
      :tableData="getSkrettingGroupTableData"
      :columns="tableColumns"
      :el-table-props="tablePropsSkrettingGroup"
      :action-column="false"
      :sortMethodForColumns="sortMethodForColumns"
      :key="$i18n.locale"
      type="white-header-table"
      layout="table"
      unique-id="user-access-managment__table"
      @row-click="handleRowClick"
      :searchOnTableData="userSearch"
    >
      <template v-slot:first_name="{ row }">
        <div>
          <p class="table-header-text captalize">
            {{ `${row.data.first_name} ${row.data.last_name}` }}
          </p>
          <p class="table-main-text">{{ row.data.email }}</p>
        </div>
      </template>
      <template v-slot:status="{ row }">
        <div
          :class="[
            'user-status-tag',
            getStatus(row.data.user_type, row.data).status === 'ACTIVE' &&
              'is-active'
          ]"
        >
          <p>{{ getStatus(row.data.user_type, row.data).lang }}</p>
        </div>
      </template>
      <template v-slot:user_type="{ row }">
        <p class="table-main-text">
          {{ ftm__capitalize($tc(getUserTypeLangStrs[row.data.user_type], 1)) }}
        </p>
      </template>
    </er-data-tables>
  </el-scrollbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UAM_UI_STATES } from "@/constants/uiStates";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  inject: ["parentConfig"],
  mixins: [filtersMixin],
  props: ["userSearch"],
  data() {
    return {
      // userSearch: "",
      loading: false
    };
  },
  computed: {
    ...mapGetters("userAccessManagement", {
      getFilteredUAMList: "getFilteredUAMList",
      getSidePanelState: "getSidePanelState"
    }),
    tableProps: function() {
      console.log("getFilteredUAMList", this.getFilteredUAMList);
      return {
        size: "mini",
        rowClassName: this.rowClassName,
        defaultSort: {
          prop: "sort_user_type_key",
          order: "ascending"
        }
      };
    },
    tablePropsSkrettingGroup: function() {
      console.log("getFilteredUAMList", this.getFilteredUAMList);
      return {
        size: "mini",
        showHeader: false,
        rowClassName: this.rowClassName,
        defaultSort: {
          prop: "sort_user_type_key",
          order: "ascending"
        }
      };
    },
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    getSkrettingGroupTableData() {
      return this.getFilteredUAMList.filter((item, i) => {
        if (
          ["ACCOUNT_MANAGER_SUB_USER", "ACCOUNT_MANAGER"].includes(
            item.user_type
          )
        ) {
          return item;
        }
      });
    },
    getOtherUserTableData() {
      return this.getFilteredUAMList.filter((item, i) => {
        if (
          !["ACCOUNT_MANAGER_SUB_USER", "ACCOUNT_MANAGER"].includes(
            item.user_type
          )
        ) {
          return item;
        }
      });
    },
    getSidePanelUserId() {
      return this.getUserData._id;
    },
    getUserTypeLangStrs() {
      return this.parentConfig.USER_TYPE;
    },
    tableColumns() {
      return {
        first_name: {
          prop: "name",
          label: this.$t("Comn_name"),
          sortMethod: this.$commonUtils.naturalSort("code"),
          minWidth: 120,
          sortable: true,
          searchProperty: "name_email_mobile",
          enableSearch: true
        },
        status: {
          prop: "status",
          label: this.$t("Comn_status"),
          searchProperty: "ui_type",
          enableSearch: true
        },
        user_type: {
          prop: "sort_user_type_key",
          label: this.$t("Usrs_role"),
          sortable: "custom",
          searchProperty: "sort_user_type_key",
          enableSearch: true
        }
      };
    },
    sortMethodForColumns: function() {
      return {
        sort_user_type_key: (a, b) =>
          this.$commonUtils.alphaNumericComparator(a, b)
      };
    }
  },
  methods: {
    ...mapActions("userAccessManagement", {
      changeSidePanelState: "changeSidePanelState"
    }),
    getStatus(type, data) {
      if (type === "NO_ACCESS") {
        return { status: "IN_ACTIVE", lang: this.$t("Comn_inactive") };
      } else if (type === "ACCOUNT_MANAGER_SUB_USER") {
        return data.status === "ACTIVE"
          ? { status: "ACTIVE", lang: this.$t("Comn_active") }
          : { status: "IN_ACTIVE", lang: this.$t("Comn_inactive") };
      } else {
        return { status: "ACTIVE", lang: this.$t("Comn_active") };
      }
    },
    handleRowClick: async function(row, column, event) {
      this.changeSidePanelState({
        ui_state: UAM_UI_STATES.DISPLAY_SUB_USER_DETAILS,
        user: row
      });
    },
    rowClassName({ row, rowIndex }) {
      if (row && this.getSidePanelUserId === row._id) {
        return "selected-row";
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss">
.el-table__append-wrapper {
  font-family: "Lato", "HelveticaNeue", "Arial", "sans-serif";
  // height: 20px;
  background: #f2f0f9;
  color: #0a2463;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 6px 7px;
}
.user-list-table {
  &.user-table-scroll {
    position: relative;
    @include responsiveProperty(height, 74vh, 78vh, 82vh);
    .user-table-scroll-wrap {
      height: 100%;
      overflow-y: auto;
      overflow-x: auto;
    }
    .user-table-scroll-view {
      padding-right: 10px;
    }
  }
  .user-status-tag {
    background: #f2f0f9;
    border-radius: 10px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0a2463;
    &:before {
      content: "\2B24";
      color: #0a2463;
      font-size: 13px;
      margin-right: 5px;
      font-size: 8px;
    }
    &.is-active {
      background: #33a02c;
      color: white;
      &:before {
        color: white;
      }
    }
  }
}
</style>
