<script>
import filtersMixin from "@/mixins/filtersMixin";
import {
  FTPermissions,
  permissionActionMap,
  FTPermissionsToActionsObj
} from "@/middleware/pageAccessManager";
import User from "@/model/user";

export default {
  mixins: [filtersMixin],
  props: {
    layout: {
      required: true,
      default: () => []
    },
    permissionsList: {
      default: () => []
    },
    locationsList: {
      default: () => []
    },
    userDetails: {
      default: () => new User()
    },
    editable: {
      default: false
    }
  },
  data: function() {
    return {
      isIndeterminatePermissions: true,
      isIndeterminateLocations: false,
      checkAllLocations: false,
      checkAllPermissions: false,
      checkedPermissions: [],
      checkedParsedPermissions: permissionActionMap(false)
    };
  },
  computed: {
    locations() {
      return this.locationsList;
    },
    locationIdToLocation() {
      return this.locations.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});
    },
    locationIds() {
      return this.locations.map(location => location._id);
    },
    getSelectedUserType() {
      return this.userDetails.user_type;
    },
    getCheckedLocationsId() {
      return this.userDetails.allowed_locations.filter(
        locationId => this.locationIdToLocation[locationId]
      );
    },
    getCheckedLocations() {
      return this.getCheckedLocationsId
        .map(locationId => {
          return this.locationIdToLocation[locationId];
        })
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.name, b.name)
        );
    },
    getAllPermissions() {
      return FTPermissions.reduce((accPermissions, permissionKey) => {
        return FTPermissionsToActionsObj[permissionKey].reduce(
          (accPermissions2, actionKey) => {
            accPermissions2.push(
              this.permissionsToBkPermissionsObj[permissionKey][actionKey]
            );
            return accPermissions2;
          },
          accPermissions
        );
      }, []);
    },
    permissionsToBkPermissionsObj() {
      return this.$gblUAMPermissionsToPermissionDetailsList.reduce(
        (acc, permissionDetails) => {
          acc[
            permissionDetails.key
          ] = permissionDetails.availableActions.reduce((accAction, action) => {
            accAction[action.name] = {
              permission: permissionDetails,
              action: action,
              actionBkPermissions: action.permissions
            };
            return accAction;
          }, {});
          return acc;
        },
        {}
      );
    },
    allPermissions() {
      return this.permissionsList
        .map(({ availableActions }) => {
          return availableActions;
        })
        .flat(1);
    }
  },
  watch: {
    userDetails: {
      handler: function(newValue, oldValue) {
        this.handleInitPermissionsChecks();
        this.handleInitLocationsChecks(this.getCheckedLocationsId);
      },
      deep: true
    }
  },
  mounted() {
    this.handleInitPermissionsChecks();
    this.handleInitLocationsChecks(this.getCheckedLocationsId);
  },
  render(h) {
    return (
      <el-row class="page-access-component">
        {this.layout.map(item => {
          const mapItemToView = {
            permissions: this.editable
              ? this.getPermissionsEdit
              : this.getPermissionView,
            locations: this.editable
              ? this.getLocationEdit
              : this.getLocationView
          };
          return mapItemToView[item](h);
        })}
      </el-row>
    );
  },
  methods: {
    getLocationEdit(h) {
      return (
        <el-row class="sub-user-locations-selection">
          <layout-toolbar justify="start" class="sub-user-details-header">
            <span class="material-icons-outlined">person_pin_circle</span>
            <p class="sub-user-details-header__title">
              {this.ftm__capitalize(this.$tc("Comn_location.case.lower", 2))}
            </p>
          </layout-toolbar>
          <el-row class="locations-select-all right-panel-content-alignment">
            <el-checkbox
              indeterminate={this.isIndeterminateLocations}
              value={this.checkAllLocations}
              onInput={this.handleCheckAllLocations}
            >
              {this.ftm__capitalize(this.$t("select_all"))}
            </el-checkbox>
          </el-row>
          <el-row class="locations-item-container right-panel-content-alignment">
            <el-checkbox-group
              value={this.getCheckedLocationsId}
              onInput={this.handleCheckedLocationsChange}
            >
              {this.locations.map(location => {
                return (
                  <el-checkbox key={location._id} label={location._id}>
                    {this.ftm__capitalize(location.name)}
                  </el-checkbox>
                );
              })}
            </el-checkbox-group>
          </el-row>
        </el-row>
      );
    },
    getLocationView(h) {
      return (
        <el-row class="sub-user-locations-selection">
          <layout-toolbar
            justify="start"
            class="user-information-section__header"
          >
            <span class="material-icons-outlined">person_pin_circle</span>
            <p class="sub-user-details-header__title">
              {this.ftm__capitalize(this.$tc("Comn_location.case.lower", 2))}
            </p>
          </layout-toolbar>
          <layout-toolbar
            justify="start"
            class="locations-item-container right-panel-content-alignment"
          >
            <el-checkbox-group value={this.getCheckedLocationsId}>
              {this.locations.map(location => {
                return (
                  <el-checkbox key={location._id} label={location._id}>
                    {this.ftm__capitalize(location.name)}
                  </el-checkbox>
                );
              })}
            </el-checkbox-group>
          </layout-toolbar>
        </el-row>
      );
    },
    getPermissionView(h) {
      return (
        <el-row class="sub-user-permission-selection">
          <layout-toolbar
            justify="start"
            class="user-information-section__header"
          >
            <span class="material-icons-outlined">verified_user</span>
            <p class="sub-user-details-header__title">
              {this.ftm__capitalize(this.$tc("Comn_permissions", 1))}
            </p>
          </layout-toolbar>
          <el-row key={this.getSelectedUserType}>
            <el-checkbox-group
              class="permissions-checkbox-container right-panel-content-alignment"
              value={this.checkedPermissions}
            >
              {this.permissionsList.map(permission => {
                const newPermission = permission;
                const allowedActions = newPermission.availableActions;
                return (
                  <el-row class="permissions-checkbox-row">
                    <el-col span={8}>
                      <p class="permissions-checkbox-row__title">
                        {newPermission.name}
                      </p>
                    </el-col>
                    <el-col span={16}>
                      {allowedActions.map(action => {
                        return (
                          <el-checkbox
                            label={
                              this.permissionsToBkPermissionsObj[
                                permission.key
                              ][action.name]
                            }
                            key={`${newPermission.name}_${action.name}`}
                          >
                            {this.getLabel(action.name)}
                          </el-checkbox>
                        );
                      })}
                    </el-col>
                  </el-row>
                );
              })}
            </el-checkbox-group>
          </el-row>
        </el-row>
      );
    },
    getPermissionsEdit(h) {
      return (
        <el-row class="sub-user-permission-selection">
          <layout-toolbar justify="start" class="sub-user-details-header">
            <span class="material-icons-outlined">verified_user</span>
            <p class="sub-user-details-header__title">
              {this.ftm__capitalize(this.$tc("Comn_permissions", 1))}
            </p>
          </layout-toolbar>
          <el-row class="permissions-select-all right-panel-content-alignment">
            <el-checkbox
              indeterminate={this.isIndeterminatePermissions}
              value={this.checkAllPermissions}
              onInput={this.handleCheckAllPermissions}
            >
              {this.ftm__capitalize(this.$t("select_all"))}
            </el-checkbox>
          </el-row>
          <el-row key={this.getSelectedUserType}>
            <el-checkbox-group
              class="permissions-checkbox-container right-panel-content-alignment"
              value={this.checkedPermissions}
              onInput={$event => this.handlePermissionsChange($event)}
            >
              {this.permissionsList.map(permission => {
                const newPermission = permission;
                const allowedActions = newPermission.availableActions;
                return (
                  <el-row class="permissions-checkbox-row">
                    <el-col span={8}>
                      <p class="permissions-checkbox-row__title">
                        {newPermission.name}
                      </p>
                    </el-col>
                    <el-col span={16}>
                      {allowedActions.map(action => {
                        return (
                          <el-checkbox
                            label={
                              this.permissionsToBkPermissionsObj[
                                permission.key
                              ][action.name]
                            }
                            key={`${newPermission.name}_${action.name}`}
                          >
                            {this.getLabel(action.name)}
                          </el-checkbox>
                        );
                      })}
                    </el-col>
                  </el-row>
                );
              })}
            </el-checkbox-group>
          </el-row>
        </el-row>
      );
    },
    handleInitLocationsChecks(value) {
      const checkedCount = value.length;
      this.checkAllLocations = checkedCount === this.locations.length;
      this.isIndeterminateLocations =
        checkedCount > 0 && checkedCount < this.locations.length;
    },
    handleCheckedLocationsChange: function(value) {
      this.handleInitLocationsChecks(value);
      this.$emit("locations-changed", value);
    },
    handleCheckAllLocations: function(value) {
      this.isIndeterminateLocations = false;
      this.checkAllLocations = value;
      this.$emit("locations-changed", value ? this.locationIds : []);
    },
    handleInitPermissionsChecks() {
      this.initCheckedParsedPermissions();
      this.initCheckedPermissions();
      this.checkAllPermissions = this.areAllPermissionsChecked();
      this.isIndeterminatePermissions = this.checkIndeterminatePermissions();
    },
    initCheckedParsedPermissions() {
      this.checkedParsedPermissions = this.userDetails.parsedPermissions;
    },
    initCheckedPermissions() {
      this.checkedPermissions = FTPermissions.reduce(
        (accPermissions, permissionKey) => {
          return FTPermissionsToActionsObj[permissionKey].reduce(
            (accPermissions2, actionKey) => {
              if (
                this.checkedParsedPermissions &&
                this.checkedParsedPermissions[permissionKey][actionKey]
              ) {
                accPermissions2.push(
                  this.permissionsToBkPermissionsObj[permissionKey][actionKey]
                );
              }
              return accPermissions2;
            },
            accPermissions
          );
        },
        []
      );
    },
    handleCheckedPermissionsChange: function(value) {
      const currPermissions = [
        ...new Set(Object.values(this.checkedPermissionsNew).flat(2))
      ];
      this.$emit("selectedPermissions", currPermissions);
    },
    areAllPermissionsChecked() {
      return this.checkedPermissions.length === this.allPermissions.length;
    },
    checkIndeterminatePermissions() {
      return (
        this.checkedPermissions.length > 0 &&
        this.checkedPermissions.length < this.allPermissions.length
      );
    },
    handleCheckAllPermissions: function(value) {
      const checkedPermissions = value
        ? [...new Set(this.allPermissions.map(x => x.permissions).flat(1))]
        : [];
      this.isIndeterminatePermissions = false;
      this.checkAllPermissions = value;
      const newParsedPermissions = permissionActionMap(value);
      this.$emit(
        "permissions-changed",
        checkedPermissions,
        newParsedPermissions
      );
    },
    handlePermissionsChange: function(value) {
      const newParsedPermissions = value.reduce((acc, x) => {
        acc[x.permission.key][x.action.name] = true;
        return acc;
      }, this.$lodash.cloneDeep(permissionActionMap()));
      this.makeValidParsedPermissions(newParsedPermissions, value);
      this.$emit(
        "permissions-changed",
        this.normalizeCheckBoxGroupArray(value),
        newParsedPermissions
      );
    },
    makeValidParsedPermissions(newParsedPermissions, permissionsList) {
      FTPermissions.reduce((accPermissions, permissionKey) => {
        return FTPermissionsToActionsObj[permissionKey].reduce(
          (accPermissions2, actionKey) => {
            if (actionKey === "VIEW") {
              return accPermissions2;
            }
            const canUserViewPreviously = this.checkedParsedPermissions[
              permissionKey
            ].VIEW;
            const canUserViewAtPresent = accPermissions2[permissionKey].VIEW;
            const canUserPerformCurrAction =
              accPermissions2[permissionKey][actionKey];
            if (
              [
                !canUserViewPreviously,
                !canUserViewAtPresent,
                canUserPerformCurrAction
              ].every(x => x)
            ) {
              accPermissions2[permissionKey].VIEW = true;
              permissionsList.push(
                this.permissionsToBkPermissionsObj[permissionKey].VIEW
              );
            }
            if (
              [
                canUserViewPreviously,
                !canUserViewAtPresent,
                canUserPerformCurrAction
              ].every(x => x)
            ) {
              accPermissions2[permissionKey][actionKey] = false;
              const indexOfPermission = this.getIndexOfPermissionInGivenList(
                permissionsList,
                this.permissionsToBkPermissionsObj[permissionKey][actionKey]
              );
              permissionsList.splice(indexOfPermission, 1);
            }
            return accPermissions2;
          },
          accPermissions
        );
      }, newParsedPermissions);
    },
    normalizeCheckBoxGroupArray(value) {
      return value.map(x => x.actionBkPermissions).flat(1);
    },
    getIndexOfPermissionInGivenList(permissionList, permissionToFind) {
      return permissionList.indexOf(permissionToFind);
    },
    getLabel(permission) {
      if (permission === "VIEW") {
        return this.$t("Comn_view");
      } else if (permission === "UPDATE") {
        return this.$t("Comn_update");
      } else {
        return this.$t("Comn_delete");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-access-component {
  @include er-checkbox-checked(true);
  .sub-user-permission-selection + .sub-user-locations-selection {
    margin-top: 18px;
  }
  &::v-deep .el-checkbox__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .locations-item-container {
    flex-wrap: wrap;
    &::v-deep .el-checkbox-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .el-checkbox {
        @include responsiveProperty(width, 100px, 125px, 125px);
      }
    }
    .location-item:not(:first-child) {
      margin-left: 0px;
    }
    .location-item {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
      &__icon {
        margin-right: 10px;
        vertical-align: middle;
      }
      &__title {
        @include small-text;
      }
    }
  }

  .permissions-checkbox-container {
    display: flex;
    flex-direction: column;
    @include normal-text;
    .permissions-checkbox-row {
      display: flex;
      margin-bottom: 2px;
      margin-top: 2px;
      align-items: center;
      .permissions-checkbox-row__title {
        width: 200px;
        color: #6c7b8a;
      }
      p {
        @include responsiveProperty(
          font-size,
          $app_font_size_small,
          $app_font_size_1,
          $app_font_size_2
        );
      }
      .el-checkbox {
        width: 80px;
      }
    }
  }
}
</style>
